import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import { DecimalNumberField } from '../../components/DecimalNumberField';
import { UploadFileButton } from '../../components/UploadFileButton';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { portfolioFormFields, subscribedPortfolioFormFields } from '../../forms/fields/formFields';
import { getSubscribedPortfolioFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { fetchAppConfigs } from '../../redux/modules/appConfig/appConfig.actions';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { fetchAssets } from '../../redux/modules/asset/asset.actions';
import { assetSelector } from '../../redux/modules/asset/asset.selectors';
import { fetchBenchmarks } from '../../redux/modules/benchmark/benchmark.actions';
import { benchmarkSelector } from '../../redux/modules/benchmark/benchmark.selectors';
import { fetchCurrencys } from '../../redux/modules/currency/currency.actions';
import { currencySelector } from '../../redux/modules/currency/currency.selectors';
import { fetchFundManagers } from '../../redux/modules/fundManager/fundManager.actions';
import { fundManagerSelector } from '../../redux/modules/fundManager/fundManager.selectors';
import { fetchGicsOptions, fetchIndexFunds } from '../../redux/modules/indexFund/indexFund.actions';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';
import { fetchPortfolioFileTypeOptions } from '../../redux/modules/portfolio/portfolio.actions';
import { portfolioSelector } from '../../redux/modules/portfolio/portfolio.selectors';
import {
  fetchSubscribedPortfolio,
  fetchSubscribedPortfolioStatusOptions,
  resetEditSubscribedPortfolio,
  saveSubscribedPortfolio,
  updateSubscribedPortfolio,
} from '../../redux/modules/subscribedPortfolio/subscribedPortfolio.actions';
import { subscribedPortfolioSelector } from '../../redux/modules/subscribedPortfolio/subscribedPortfolio.selectors';
import { locations } from '../../routes/locations';
import { ConfigKey } from '../../types/appConfig';
import { UpdatePortfolioRequestFile } from '../../types/portfolio';
import {
  UpdateSubscribedPortfolioRequestFile,
  UpdateSubscribedPortfolioRequestAssociatedIndexFund,
} from '../../types/subscribedPortfolio';
import { round2 } from '../../utils/number';
import { getAssetChartItems, getAssetChartItemsByGics } from '../../utils/portfolio';
import AssetDoughnutChart from '../Portfolio/AssetDoughnutChart';
import { FakeButtonLink } from '../../components/FakeLink';
import { Changes } from '../../components/Changes';
import { productTypeSelector } from '../../redux/modules/productType/product-type.selectors';
import { fetchProductTypes } from '../../redux/modules/productType/product-type.actions';

const marksPercentage = [
  { value: 0, label: '0%' },
  { value: 20, label: '20%' },
  { value: 50, label: '50%' },
  { value: 80, label: '80%' },
  { value: 100, label: '100%' },
];
const marksSharpRatio = [
  { value: 0, label: '0' },
  { value: 0.1, label: '0.1' },
  { value: 0.2, label: '0.2' },
  { value: 0.3, label: '0.3' },
  { value: 0.4, label: '0.4' },
  { value: 0.5, label: '0.5' },
  { value: 0.6, label: '0.6' },
  { value: 0.7, label: '0.7' },
  { value: 0.8, label: '0.8' },
  { value: 0.9, label: '0.9' },
  { value: 1, label: '1' },
];

export function SubscribedPortfolio() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [chartData, setChartData] = useState([{ label: '', value: 0 }]);
  const [formSchema, setFormSchema] = useState(getSubscribedPortfolioFormSchema(0));
  const {
    fetchStatus,
    editSubscribedPortfolio,
    saveStatus,
    updateStatus,
    subscribedPortfolioStatusOptions,
    fetchSubscribedPortfolioStatusOptionsStatus,
    error: subscribedPortfolioError,
  } = useSelector(subscribedPortfolioSelector);
  const { portfolioFileTypeOptions, fetchPortfolioFileTypeOptionsStatus } = useSelector(portfolioSelector);
  const { fetchStatus: assetFetchStatus, assets, error: assetError } = useSelector(assetSelector);
  const {
    fetchStatus: indexFundFetchStatus,
    indexFunds,
    error: indexFundError,
    fetchStatus: gicsFetchStatus,
    gicsOptions,
  } = useSelector(indexFundSelector);
  const { fetchStatus: currencyFetchStatus, currencys, error: currencyError } = useSelector(currencySelector);
  const { fetchStatus: benchmarkFetchStatus, benchmarks, error: benchmarkError } = useSelector(benchmarkSelector);
  const { fetchStatus: appConfigFetchStatus, appConfigs, error: appConfigError } = useSelector(appConfigSelector);
  const {
    fetchStatus: productTypeFetchStatus,
    productTypes,
    error: productTypeError,
  } = useSelector(productTypeSelector);
  const {
    fetchStatus: fundManagerFetchStatus,
    fundManagers,
    error: fundManagerError,
  } = useSelector(fundManagerSelector);
  const { enqueueSnackbar } = useSnackbar();
  const isLoading =
    fetchStatus === fetchStatuses.pending ||
    assetFetchStatus === fetchStatuses.pending ||
    gicsFetchStatus === fetchStatuses.pending ||
    currencyFetchStatus === fetchStatuses.pending ||
    fundManagerFetchStatus === fetchStatuses.pending ||
    indexFundFetchStatus === fetchStatuses.pending ||
    benchmarkFetchStatus === fetchStatuses.pending ||
    appConfigFetchStatus === fetchStatuses.pending ||
    fetchPortfolioFileTypeOptionsStatus === fetchStatuses.pending ||
    fetchSubscribedPortfolioStatusOptionsStatus === fetchStatuses.pending ||
    productTypeFetchStatus === fetchStatuses.pending;
  const isSaveLoading = isLoading || saveStatus === fetchStatuses.pending;
  const error =
    subscribedPortfolioError ||
    currencyError ||
    fundManagerError ||
    assetError ||
    indexFundError ||
    benchmarkError ||
    appConfigError ||
    productTypeError;
  const formik = useFormik({
    initialValues: {
      [subscribedPortfolioFormFields.nameEn]: '',
      [subscribedPortfolioFormFields.nameAr]: '',
      [subscribedPortfolioFormFields.descriptionEn]: '',
      [subscribedPortfolioFormFields.descriptionAr]: '',
      [subscribedPortfolioFormFields.objectiveEn]: '',
      [subscribedPortfolioFormFields.objectiveAr]: '',
      [subscribedPortfolioFormFields.shariaaComplaint]: false,
      [subscribedPortfolioFormFields.status]: 0,
      [subscribedPortfolioFormFields.currency]: 0,
      [subscribedPortfolioFormFields.manager]: 0,
      [subscribedPortfolioFormFields.expectedMinimumReturn]: 0,
      [subscribedPortfolioFormFields.targetExpectedReturn]: 0,
      [subscribedPortfolioFormFields.expectedMaximumReturn]: 0,
      [subscribedPortfolioFormFields.displayByGics]: false,
      [subscribedPortfolioFormFields.targetExpectedRisk]: 0,
      [subscribedPortfolioFormFields.benchmark]: 0,
      [subscribedPortfolioFormFields.productType]: 0,
      [subscribedPortfolioFormFields.sharpRatio]: 0,
      [subscribedPortfolioFormFields.files]: [] as UpdateSubscribedPortfolioRequestFile[],
      [subscribedPortfolioFormFields.associatedIndexFunds]: [] as UpdateSubscribedPortfolioRequestAssociatedIndexFund[],
    },
    validationSchema: formSchema,
    onSubmit: (formValues) => {
      // return alert(JSON.stringify(formValues, null, 4));

      formValues.associatedIndexFunds = formValues.associatedIndexFunds.map((aif) => ({ ...aif, weight: +aif.weight }));

      if (Number(id)) {
        dispatch(updateSubscribedPortfolio({ id: Number(id), ...formValues }));
      } else {
        dispatch(saveSubscribedPortfolio(formValues));
      }
    },
  });
  const { handleSubmit, values, handleChange, touched, errors, setValues } = formik;
  const cashAllocationPercentage = Number(
    appConfigs.find((i) => i.key === ConfigKey.CACHE_ALLOCATION_PERCENTAGE)?.value || 0,
  );

  useEffect(() => {
    const chartItems = values[subscribedPortfolioFormFields.displayByGics]
      ? getAssetChartItemsByGics(indexFunds, values[portfolioFormFields.associatedIndexFunds], cashAllocationPercentage)
      : getAssetChartItems(indexFunds, values[portfolioFormFields.associatedIndexFunds], cashAllocationPercentage);
    setChartData(chartItems);
  }, [values[subscribedPortfolioFormFields.displayByGics]]);

  useEffect(() => {
    setFormSchema(getSubscribedPortfolioFormSchema(cashAllocationPercentage));
  }, [cashAllocationPercentage]);

  useEffect(() => {
    dispatch(fetchFundManagers({ limit: 100, offset: 0 }));
    dispatch(fetchCurrencys({ limit: 100, offset: 0 }));
    dispatch(fetchAssets({ limit: 100, offset: 0 }));
    dispatch(fetchGicsOptions());
    dispatch(fetchIndexFunds({ limit: 100, offset: 0 }));
    dispatch(fetchBenchmarks({ limit: 100, offset: 0 }));
    dispatch(fetchAppConfigs());
    dispatch(fetchSubscribedPortfolioStatusOptions());
    dispatch(fetchPortfolioFileTypeOptions());
    dispatch(fetchProductTypes({ limit: 100, offset: 0 }));

    return () => {
      dispatch(resetEditSubscribedPortfolio());
    };
  }, []);

  useEffect(() => {
    if (saveStatus === fetchStatuses.success) {
      dispatch(fetchSubscribedPortfolio(Number(id)));
      enqueueSnackbar('Subscribed IndexFund saved!', { variant: 'success' });
    }
    if (updateStatus === fetchStatuses.success) {
      dispatch(fetchSubscribedPortfolio(Number(id)));
      enqueueSnackbar('Subscribed IndexFund updated!', { variant: 'success' });
    }
  }, [saveStatus, updateStatus]);

  useEffect(() => {
    if (Number(id)) {
      dispatch(fetchSubscribedPortfolio(Number(id)));
    }
  }, [id]);

  useEffect(() => {
    if (editSubscribedPortfolio?.id && editSubscribedPortfolio?.id !== Number(id)) {
      navigate(locations.subscribedPortfolio(editSubscribedPortfolio?.id));
      return;
    }

    if (editSubscribedPortfolio) {
      const associatedIndexFunds =
        editSubscribedPortfolio.associatedIndexFunds?.map((aif) => ({
          id: aif.id,
          indexFund: aif?.indexFund?.id,
          weight: aif.weight,
        })) || [];

      setValues({
        [subscribedPortfolioFormFields.nameEn]: editSubscribedPortfolio.nameEn,
        [subscribedPortfolioFormFields.nameAr]: editSubscribedPortfolio.nameAr,
        [subscribedPortfolioFormFields.descriptionEn]: editSubscribedPortfolio.descriptionEn,
        [subscribedPortfolioFormFields.descriptionAr]: editSubscribedPortfolio.descriptionAr,
        [subscribedPortfolioFormFields.objectiveEn]: editSubscribedPortfolio.objectiveEn,
        [subscribedPortfolioFormFields.objectiveAr]: editSubscribedPortfolio.objectiveAr,
        [subscribedPortfolioFormFields.shariaaComplaint]: editSubscribedPortfolio.shariaaComplaint,
        [subscribedPortfolioFormFields.status]: editSubscribedPortfolio.status?.id,
        [subscribedPortfolioFormFields.currency]: editSubscribedPortfolio.currency?.id,
        [subscribedPortfolioFormFields.manager]: editSubscribedPortfolio.manager?.id,
        [subscribedPortfolioFormFields.expectedMinimumReturn]: editSubscribedPortfolio.expectedMinimumReturn || 0,
        [subscribedPortfolioFormFields.targetExpectedReturn]: editSubscribedPortfolio.targetExpectedReturn || 0,
        [subscribedPortfolioFormFields.expectedMaximumReturn]: editSubscribedPortfolio.expectedMaximumReturn || 0,
        [subscribedPortfolioFormFields.targetExpectedRisk]: editSubscribedPortfolio.targetExpectedRisk || 0,
        [subscribedPortfolioFormFields.benchmark]: editSubscribedPortfolio.benchmark?.id || 0,
        [subscribedPortfolioFormFields.productType]: editSubscribedPortfolio.productType?.id || 0,
        [subscribedPortfolioFormFields.sharpRatio]: +editSubscribedPortfolio.sharpRatio || 0,
        [subscribedPortfolioFormFields.displayByGics]: editSubscribedPortfolio.displayByGics || false,
        [subscribedPortfolioFormFields.files]:
          editSubscribedPortfolio.files?.map((f) => ({
            id: f.id,
            fileId: f.fileId,
            portfolioFileType: f.portfolioFileType?.id,
            isPublic: f.isPublic,
          })) || [],
        [subscribedPortfolioFormFields.associatedIndexFunds]: associatedIndexFunds,
      });

      setChartData(getAssetChartItems(indexFunds, associatedIndexFunds, cashAllocationPercentage));
    }
  }, [editSubscribedPortfolio]);

  const getFilesKey = (index: number) => `file-${index}`;
  const getAssetKey = (index: number) => `asset-${index}`;
  const getAssociatedIndexFundsKey = (index: number) => `associated-index-funds-${index}`;
  const valueText = (value: number) => `${value}%`;

  function getSelectedIndexFund(indexFundId: number) {
    return indexFunds.find((i) => i.id === indexFundId);
  }

  function getSelectedPortfolioFileType(fileTypeId: number) {
    return portfolioFileTypeOptions.find((i) => i.id === fileTypeId);
  }

  function getOriginalIndexFundWeight(indexFundId: number, index: number) {
    const originalIndexFund = editSubscribedPortfolio?.originalPortfolio?.associatedIndexFunds?.find(
      (item) => item.indexFund.id === indexFundId,
    );

    return originalIndexFund?.weight || editSubscribedPortfolio?.associatedIndexFunds?.[index]?.weight || 0;
  }

  // TODO: move to separate component
  const renderAssetsData = () =>
    values[portfolioFormFields.associatedIndexFunds].map((aif, index) => (
      <Grid container item spacing={3} key={getAssetKey(index)}>
        <Grid item xs={12} md={5}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel id="asset-label" required>
                Asset
              </InputLabel>
              <Select
                labelId="asset-label"
                id={`associatedIndexFunds.${index}.asset`}
                name={`associatedIndexFunds.${index}.asset`}
                value={getSelectedIndexFund(aif.indexFund)?.asset?.id || 0}
                label="Asset Class"
                disabled
              >
                {assets.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.nameEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControlLabel
              sx={{ width: '100%', marginX: 0 }}
              label="Weight"
              labelPlacement="top"
              control={
                <FormControl fullWidth margin="none" disabled>
                  <Slider
                    disabled
                    aria-label="Weight"
                    defaultValue={1}
                    id={`associatedIndexFunds.${index}.weight`}
                    name={`associatedIndexFunds.${index}.weight`}
                    value={aif.weight || 0}
                    valueLabelFormat={valueText}
                    getAriaValueText={valueText}
                    valueLabelDisplay="on"
                    marks={marksPercentage}
                  />
                </FormControl>
              }
            />
          )}
        </Grid>
      </Grid>
    ));

  // TODO: move to separate component
  const renderGicsData = () => {
    const gicsByWeight = values[portfolioFormFields.associatedIndexFunds].reduce(
      (res, aif) => {
        const indexFund = getSelectedIndexFund(aif.indexFund);

        if (indexFund) {
          for (const gics of indexFund.gics as any) {
            res[gics.gics.id!] = (res[gics.gics.id!] || 0) + (gics.weight * aif.weight) / 100;
          }
        }

        return res;
      },
      {} as Record<number, number>,
    );

    return Object.keys(gicsByWeight).map((key, index) => (
      <Grid container item spacing={3} key={getAssetKey(index)}>
        <Grid item xs={12} md={5}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel id="gics-label" required>
                Gics
              </InputLabel>
              <Select
                labelId="gics-label"
                id={`gics.${index}.gics`}
                name={`gics.${index}.gics`}
                value={key}
                label="Gics"
                disabled
              >
                {gicsOptions.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.nameEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControlLabel
              sx={{ width: '100%', marginX: 0 }}
              label="Weight"
              labelPlacement="top"
              control={
                <FormControl fullWidth margin="none" disabled>
                  <Slider
                    disabled
                    aria-label="Weight"
                    defaultValue={1}
                    id={`gics.${index}.weight`}
                    name={`gics.${index}.weight`}
                    value={round2(gicsByWeight[key] || 0)}
                    valueLabelFormat={valueText}
                    getAriaValueText={valueText}
                    valueLabelDisplay="on"
                    marks={marksPercentage}
                  />
                </FormControl>
              }
            />
          )}
        </Grid>
      </Grid>
    ));
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Grid container flex={1} flexDirection="row" justifyContent="flex-start" alignItems="center">
              <Typography color="text.primary" gutterBottom>
                Subscribed Portfolio
              </Typography>
              {editSubscribedPortfolio?.originalPortfolio && (
                <Tooltip
                  title={`(${editSubscribedPortfolio.originalPortfolio.id}) ${editSubscribedPortfolio.originalPortfolio.nameEn}`}
                  followCursor
                >
                  <FakeButtonLink
                    width="200px"
                    startIcon={<OpenInNewIcon />}
                    href={locations.portfolio(editSubscribedPortfolio.originalPortfolio.id)}
                  >
                    {`(${editSubscribedPortfolio.originalPortfolio.id}) ${editSubscribedPortfolio.originalPortfolio.nameEn}`}
                  </FakeButtonLink>
                </Tooltip>
              )}
            </Grid>
            <Grid container spacing={2} paddingX={2}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="nameEn"
                    name={subscribedPortfolioFormFields.nameEn}
                    label="Name En"
                    type="nameEn"
                    value={values[subscribedPortfolioFormFields.nameEn]}
                    onChange={handleChange}
                    error={touched.nameEn && !!errors.nameEn}
                    helperText={touched.nameEn && errors.nameEn}
                    disabled={Number(id) > 0}
                    autoComplete="nameEn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    id="nameAr"
                    fullWidth
                    name={subscribedPortfolioFormFields.nameAr}
                    label="Name Ar"
                    value={values[subscribedPortfolioFormFields.nameAr]}
                    onChange={handleChange}
                    error={touched.nameAr && !!errors.nameAr}
                    helperText={touched.nameAr && errors.nameAr}
                    disabled={Number(id) > 0}
                    autoComplete="nameAr"
                    margin="normal"
                    required
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="descriptionEn"
                    name={subscribedPortfolioFormFields.descriptionEn}
                    label="Description En"
                    type="descriptionEn"
                    value={values[subscribedPortfolioFormFields.descriptionEn]}
                    onChange={handleChange}
                    error={touched.descriptionEn && !!errors.descriptionEn}
                    helperText={touched.descriptionEn && errors.descriptionEn}
                    disabled={Number(id) > 0}
                    autoComplete="descriptionEn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    id="descriptionAr"
                    fullWidth
                    name={subscribedPortfolioFormFields.descriptionAr}
                    label="Description Ar"
                    value={values[subscribedPortfolioFormFields.descriptionAr]}
                    onChange={handleChange}
                    error={touched.descriptionAr && !!errors.descriptionAr}
                    helperText={touched.descriptionAr && errors.descriptionAr}
                    disabled={Number(id) > 0}
                    autoComplete="descriptionAr"
                    margin="normal"
                    required
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.status && !!errors.status}>
                    <InputLabel id="status-label" required>
                      Status
                    </InputLabel>
                    <Select
                      labelId="status-label"
                      name={subscribedPortfolioFormFields.status}
                      value={values[subscribedPortfolioFormFields.status] || ''}
                      label="Status"
                      onChange={handleChange}
                    >
                      {subscribedPortfolioStatusOptions.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.status && <FormHelperText>{errors.status}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.currency && !!errors.currency}>
                    <InputLabel id="currency-label" required>
                      Currency
                    </InputLabel>
                    <Select
                      labelId="currency-label"
                      name={subscribedPortfolioFormFields.currency}
                      value={values[subscribedPortfolioFormFields.currency] || ''}
                      label="Currency"
                      onChange={handleChange}
                      defaultValue={0}
                      disabled={Number(id) > 0}
                    >
                      {currencys.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.currency && <FormHelperText>{errors.currency}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.manager && !!errors.manager}>
                    <InputLabel id="manager-label" required>
                      Manager
                    </InputLabel>
                    <Select
                      labelId="manager-label"
                      name={portfolioFormFields.manager}
                      value={values[portfolioFormFields.manager] || ''}
                      label="Manager"
                      onChange={handleChange}
                      required
                      defaultValue={0}
                      disabled={Number(id) > 0}
                    >
                      {fundManagers.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.manager && <FormHelperText>{errors.manager}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width={192} height={38} sx={{ mt: 2 }} />
                ) : (
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Shariaa Complaint"
                    labelPlacement="start"
                    componentsProps={{ typography: { textTransform: 'capitalize' } }}
                    name="shariaaComplaint"
                    id="shariaaComplaint"
                    control={
                      <Switch
                        name={subscribedPortfolioFormFields.shariaaComplaint}
                        checked={values[subscribedPortfolioFormFields.shariaaComplaint]}
                        onChange={handleChange}
                        disabled={Number(id) > 0}
                      />
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="objectiveEn"
                    name={subscribedPortfolioFormFields.objectiveEn}
                    label="Objective En"
                    type="objectiveEn"
                    value={values[subscribedPortfolioFormFields.objectiveEn]}
                    onChange={handleChange}
                    error={touched.objectiveEn && !!errors.objectiveEn}
                    helperText={touched.objectiveEn && errors.objectiveEn}
                    disabled={Number(id) > 0}
                    autoComplete="objectiveEn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    id="objectiveAr"
                    fullWidth
                    name={subscribedPortfolioFormFields.objectiveAr}
                    label="Objective Ar"
                    value={values[subscribedPortfolioFormFields.objectiveAr]}
                    onChange={handleChange}
                    error={touched.objectiveAr && !!errors.objectiveAr}
                    helperText={touched.objectiveAr && errors.objectiveAr}
                    disabled={Number(id) > 0}
                    autoComplete="objectiveAr"
                    margin="normal"
                    required
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.benchmark && !!errors.benchmark}>
                    <InputLabel id="benchmark-label" required>
                      Benchmark
                    </InputLabel>
                    <Select
                      labelId="benchmark-label"
                      name={subscribedPortfolioFormFields.benchmark}
                      value={values[subscribedPortfolioFormFields.benchmark] || ''}
                      label="Benchmark"
                      onChange={handleChange}
                      required
                      disabled
                      defaultValue={0}
                    >
                      {benchmarks.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.nameAr}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.benchmark && <FormHelperText>{errors.benchmark}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.productType && !!errors.productType}>
                    <InputLabel id="product-type-label" required>
                      Product Type
                    </InputLabel>
                    <Select
                      labelId="product-type-label"
                      name={subscribedPortfolioFormFields.productType}
                      value={values[subscribedPortfolioFormFields.productType] || ''}
                      label="Product Type"
                      onChange={handleChange}
                      required
                      disabled
                      defaultValue={0}
                    >
                      {productTypes.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.nameAr}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.productType && <FormHelperText>{errors.productType}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" component="h4" gutterBottom>
              Files
            </Typography>
            <FormikProvider value={formik}>
              <FieldArray
                name="files"
                render={() => (
                  <List>
                    {values[subscribedPortfolioFormFields.files].map((item, index) => (
                      <ListItem key={getFilesKey(index)}>
                        <ListItemAvatar>
                          <Avatar>
                            <UploadFileButton selectedFile={item.fileId} />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={getSelectedPortfolioFileType(item.portfolioFileType)?.name}
                          secondary={
                            item.fileId || (errors.files?.[index] as FormikErrors<UpdatePortfolioRequestFile>)?.fileId
                          }
                          primaryTypographyProps={{ fontWeight: 600 }}
                          secondaryTypographyProps={{ color: item.fileId ? 'unset' : 'red' }}
                        />
                        <Switch
                          edge="end"
                          id={`files.${index}.isPublic`}
                          name={`files.${index}.isPublic`}
                          onChange={handleChange}
                          checked={item.isPublic}
                          disabled
                          inputProps={{
                            'aria-labelledby': 'switch-file-public',
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              />
              {!!errors.files && typeof errors.files === 'string' && (
                <FormHelperText error>{errors.files}</FormHelperText>
              )}
            </FormikProvider>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Options
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={2} paddingLeft={2} paddingRight={4}>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    label="Expected Minimum Return"
                    labelPlacement="top"
                    control={
                      <FormControl
                        fullWidth
                        margin="normal"
                        error={touched.expectedMinimumReturn && !!errors.expectedMinimumReturn}
                      >
                        <Slider
                          aria-label="Expected Minimum Return"
                          defaultValue={0}
                          disabled={Number(id) > 0}
                          id={subscribedPortfolioFormFields.expectedMinimumReturn}
                          name={subscribedPortfolioFormFields.expectedMinimumReturn}
                          value={values[subscribedPortfolioFormFields.expectedMinimumReturn]}
                          valueLabelFormat={valueText}
                          onChange={handleChange}
                          getAriaValueText={valueText}
                          min={0}
                          max={100}
                          step={1}
                          valueLabelDisplay="on"
                          marks={marksPercentage}
                        />
                        {touched.expectedMinimumReturn && (
                          <FormHelperText>{errors.expectedMinimumReturn}</FormHelperText>
                        )}
                      </FormControl>
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    label="Target Expected Return"
                    labelPlacement="top"
                    control={
                      <FormControl
                        fullWidth
                        margin="normal"
                        error={touched.targetExpectedReturn && !!errors.targetExpectedReturn}
                      >
                        <Slider
                          aria-label="Target Expected Return"
                          defaultValue={0}
                          disabled={Number(id) > 0}
                          id={subscribedPortfolioFormFields.targetExpectedReturn}
                          name={subscribedPortfolioFormFields.targetExpectedReturn}
                          value={values[subscribedPortfolioFormFields.targetExpectedReturn]}
                          valueLabelFormat={valueText}
                          onChange={handleChange}
                          getAriaValueText={valueText}
                          min={0}
                          max={100}
                          step={1}
                          valueLabelDisplay="on"
                          marks={marksPercentage}
                        />
                        {touched.targetExpectedReturn && <FormHelperText>{errors.targetExpectedReturn}</FormHelperText>}
                      </FormControl>
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    label="Expected Maximum Return"
                    labelPlacement="top"
                    control={
                      <FormControl
                        fullWidth
                        margin="normal"
                        error={touched.expectedMaximumReturn && !!errors.expectedMaximumReturn}
                      >
                        <Slider
                          aria-label="Expected Maximum Return"
                          defaultValue={0}
                          disabled={Number(id) > 0}
                          id={subscribedPortfolioFormFields.expectedMaximumReturn}
                          name={subscribedPortfolioFormFields.expectedMaximumReturn}
                          value={values[subscribedPortfolioFormFields.expectedMaximumReturn]}
                          valueLabelFormat={valueText}
                          onChange={handleChange}
                          getAriaValueText={valueText}
                          min={0}
                          max={100}
                          step={1}
                          valueLabelDisplay="on"
                          marks={marksPercentage}
                        />
                        {touched.expectedMaximumReturn && (
                          <FormHelperText>{errors.expectedMaximumReturn}</FormHelperText>
                        )}
                      </FormControl>
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    label="Target Expected Risk"
                    labelPlacement="top"
                    control={
                      <FormControl
                        fullWidth
                        margin="normal"
                        error={touched.targetExpectedRisk && !!errors.targetExpectedRisk}
                      >
                        <Slider
                          aria-label="Target Expected Risk"
                          defaultValue={0}
                          disabled={Number(id) > 0}
                          id={subscribedPortfolioFormFields.targetExpectedRisk}
                          name={subscribedPortfolioFormFields.targetExpectedRisk}
                          value={values[subscribedPortfolioFormFields.targetExpectedRisk]}
                          valueLabelFormat={valueText}
                          onChange={handleChange}
                          getAriaValueText={valueText}
                          min={0}
                          max={100}
                          step={1}
                          valueLabelDisplay="on"
                          marks={marksPercentage}
                        />
                        {touched.targetExpectedRisk && <FormHelperText>{errors.targetExpectedRisk}</FormHelperText>}
                      </FormControl>
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    label="Sharp Ratio"
                    labelPlacement="top"
                    control={
                      <FormControl fullWidth margin="normal" error={touched.sharpRatio && !!errors.sharpRatio}>
                        <Slider
                          aria-label="Sharp Ratio"
                          defaultValue={0}
                          disabled={Number(id) > 0}
                          id={subscribedPortfolioFormFields.sharpRatio}
                          name={subscribedPortfolioFormFields.sharpRatio}
                          value={values[subscribedPortfolioFormFields.sharpRatio]}
                          valueLabelFormat={valueText}
                          onChange={handleChange}
                          getAriaValueText={valueText}
                          min={0}
                          max={1}
                          step={0.1}
                          valueLabelDisplay="on"
                          marks={marksSharpRatio}
                        />
                        {touched.sharpRatio && <FormHelperText>{errors.sharpRatio}</FormHelperText>}
                      </FormControl>
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" sx={{ mb: 2 }} component="h4" gutterBottom>
              {values[subscribedPortfolioFormFields.displayByGics] ? 'Gics' : 'Assets'}
            </Typography>
            <Grid container rowSpacing={2} paddingLeft={2} paddingRight={4}>
              <Grid item container columnSpacing={4}>
                <Grid item xs={12} md={12} lg={7}>
                  {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={80} />
                  ) : (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="cashAllocationPercentage"
                      name="cashAllocationPercentage"
                      label="Cash Allocation Percentage"
                      value={cashAllocationPercentage}
                      disabled
                    />
                  )}
                  {values[subscribedPortfolioFormFields.displayByGics] ? renderGicsData() : renderAssetsData()}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={5}
                  position="relative"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '40px',
                      position: 'absolute',
                      top: '60%',
                      left: 0,
                      marginTop: '-20px',
                      lineHeight: '19px',
                      textAlign: 'center',
                      zIndex: 2,
                    }}
                  >
                    {round2(
                      values[portfolioFormFields.associatedIndexFunds].reduce(
                        (res, item) => res + +item.weight,
                        cashAllocationPercentage,
                      ),
                    )}
                    %
                  </Box>
                  <AssetDoughnutChart items={chartData} />
                </Grid>
              </Grid>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>By Assets</Grid>
                <Grid item>
                  <Switch
                    name={subscribedPortfolioFormFields.displayByGics}
                    checked={values[subscribedPortfolioFormFields.displayByGics]}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>By GICS</Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              width: '100%',
              height: '100%',
              padding: 2,
            }}
          >
            <Typography color="text.primary" sx={{ mb: 2 }} gutterBottom>
              Associated Funds/Index
            </Typography>
            <FormikProvider value={formik}>
              <FieldArray
                name="associatedIndexFunds"
                render={(arrayHelpers) => (
                  <Grid container spacing={2} paddingX={2}>
                    {values[subscribedPortfolioFormFields.associatedIndexFunds] &&
                    values[subscribedPortfolioFormFields.associatedIndexFunds].length > 0 ? (
                      values[subscribedPortfolioFormFields.associatedIndexFunds].map((item, index) => (
                        <Grid container item spacing={3} key={getAssociatedIndexFundsKey(index)}>
                          <Grid item xs={12} md={6} lg={2}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <FormControl
                                fullWidth
                                margin="normal"
                                error={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  !!(
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                              >
                                <InputLabel id="fund-label" required>
                                  Fund
                                </InputLabel>
                                <Select
                                  labelId="fund-label"
                                  id={`associatedIndexFunds.${index}.fund`}
                                  name={`associatedIndexFunds.${index}.fund`}
                                  value={item.indexFund}
                                  label="Fund"
                                  disabled={Number(id) > 0}
                                  onChange={handleChange}
                                >
                                  {indexFunds.map((a) => (
                                    <MenuItem key={a.nameEn} value={a.id}>
                                      {a.nameEn}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {touched.associatedIndexFunds?.[index]?.indexFund && (
                                  <FormHelperText>
                                    {
                                      (
                                        errors.associatedIndexFunds?.[
                                          index
                                        ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                      )?.indexFund
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} lg={2}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <FormControl
                                fullWidth
                                margin="normal"
                                error={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  !!(
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                              >
                                <InputLabel id="asset-class-label" required>
                                  Asset Class
                                </InputLabel>
                                <Select
                                  labelId="asset-class-label"
                                  id={`associatedIndexFunds.${index}.asset`}
                                  name={`associatedIndexFunds.${index}.asset`}
                                  value={getSelectedIndexFund(item.indexFund)?.asset?.id}
                                  label="Asset Class"
                                  disabled={Number(id) > 0}
                                >
                                  {assets.map((a) => (
                                    <MenuItem key={a.id} value={a.id}>
                                      {a.nameEn}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {touched.associatedIndexFunds?.[index]?.indexFund && (
                                  <FormHelperText>
                                    {
                                      (
                                        errors.associatedIndexFunds?.[
                                          index
                                        ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                      )?.indexFund
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} lg={1}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id={`associatedIndexFunds.${index}.region`}
                                name={`associatedIndexFunds.${index}.region`}
                                label="Region"
                                type="text"
                                value={getSelectedIndexFund(item.indexFund)?.geographicalFocus?.nameEn}
                                disabled
                                error={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  !!(
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                                helperText={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  (
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} lg={2}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id={`associatedIndexFunds.${index}.strategy`}
                                name={`associatedIndexFunds.${index}.strategy`}
                                label="Strategy/Obj"
                                type="text"
                                value={getSelectedIndexFund(item.indexFund)?.assetClass?.nameEn || ''}
                                disabled
                                error={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  !!(
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                                helperText={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  (
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={10} md={6} lg={3} display="flex" alignItems="center">
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <>
                                <Changes
                                  prev={getOriginalIndexFundWeight(item.indexFund, index)}
                                  next={editSubscribedPortfolio?.associatedIndexFunds?.[index]?.weight || 0}
                                />
                                <DecimalNumberField
                                  margin="normal"
                                  fullWidth
                                  id={`associatedIndexFunds.${index}.weight`}
                                  name={`associatedIndexFunds.${index}.weight`}
                                  value={item.weight || 0}
                                  label="Weight %"
                                  onChange={handleChange}
                                  autoComplete="Weight"
                                  error={
                                    touched.associatedIndexFunds?.[index]?.weight &&
                                    !!(
                                      errors.associatedIndexFunds?.[
                                        index
                                      ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                    )?.weight
                                  }
                                  helperText={
                                    touched.associatedIndexFunds?.[index]?.weight &&
                                    (
                                      errors.associatedIndexFunds?.[
                                        index
                                      ] as FormikErrors<UpdateSubscribedPortfolioRequestAssociatedIndexFund>
                                    )?.weight
                                  }
                                />
                              </>
                            )}
                          </Grid>
                          <Grid container item xs={2} spacing={2} display="flex" alignItems="center">
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="Remove index fund"
                                size="small"
                                disabled={Number(id) > 0}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="Add index fund"
                                size="small"
                                disabled={Number(id) > 0}
                                onClick={() =>
                                  arrayHelpers.insert(index + 1, {
                                    fund: '',
                                    asset: '',
                                    region: '',
                                    strategy: '',
                                    weight: 0,
                                  })
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Box>
                        <Button
                          variant="outlined"
                          onClick={() => arrayHelpers.push({ fund: '', asset: 0, region: '', strategy: '', weight: 0 })}
                        >
                          Add an Index Fund
                        </Button>
                      </Box>
                    )}
                  </Grid>
                )}
              />
            </FormikProvider>
          </Paper>
        </Grid>
      </Grid>
      <Grid>
        <Box m={1} p={1} display="flex" justifyContent="right" alignItems="flex-end">
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            endIcon={<SaveIcon />}
            loading={isSaveLoading}
            loadingPosition="end"
          >
            {editSubscribedPortfolio ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      </Grid>
    </form>
  );

  return (
    <Container component="main" maxWidth="xl">
      {error && (
        <Alert severity="error">
          <pre>{error.message}</pre>
        </Alert>
      )}
      <Box mt={2}>{renderForm()}</Box>
    </Container>
  );
}
