import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { userPortfolioRequestStatusToInfo } from '../../constants/tables/userPortfolioRequest';
import { useAppDispatch } from '../../redux/hooks';
import {
  cleanUserPortfolioRequests,
  fetchUserPortfolioRequests,
} from '../../redux/modules/userPortfolioRequest/userPortfolioRequest.actions';
import { userPortfolioRequestByRequestIdSelector } from '../../redux/modules/userPortfolioRequest/userPortfolioRequest.selectors';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { FundCategory } from '../../types/indexFund';
import { UserPortfolioRequestStatus, UserPortfolioRequestType } from '../../types/userPortfolioRequest';
import { round2Format } from '../../utils/number';

interface UserPortfolioRequestTableProps {
  userPortfolioId: number;
}

export function UserPortfolioRequestTable({ userPortfolioId }: UserPortfolioRequestTableProps) {
  const requestId = `user-portfolio-details-${userPortfolioId}`;
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [columns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'type.name',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(UserPortfolioRequestType),
      width: 150,
      valueGetter: (params) => params.row?.type?.name,
    },
    {
      field: 'fundCategory.name',
      headerName: 'Fund Category',
      type: 'singleSelect',
      valueOptions: Object.values(FundCategory),
      width: 130,
      valueGetter: (params) => params.row?.fundCategory?.name,
    },
    {
      field: 'userPortfolio.fundAmount',
      headerName: 'Amount',
      type: 'number',
      width: 150,
      valueGetter: (params) => {
        if (params.row?.type?.name === UserPortfolioRequestType.REDEMPTION) {
          return params.row?.changes?.redeemAll ? 'all' : params.row?.changes?.amount;
        }
        return params.row?.changes?.categoryAmount ?? params.row?.userPortfolio?.fundAmount;
      },
      valueFormatter: (value) => {
        let formattedValue;
        if (value?.value === 'all') {
          formattedValue = value?.value;
        } else if (typeof value?.value === 'number') {
          formattedValue = round2Format(value?.value);
        } else {
          formattedValue = value?.value;
        }
        return formattedValue;
      },
    },
    {
      field: 'userPortfolio.userId',
      headerName: 'User',
      width: 120,
      valueGetter: (params) => params.row?.userPortfolio?.userId,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.user(params.value)}>
            {params.value}
          </FakeButtonLink>
        );
      },
    },
    {
      field: 'userPortfolio.subscribedPortfolio.nameEn',
      headerName: 'Portfolio',
      type: 'string',
      width: 250,
      flex: 1,
      valueGetter: (params) => {
        if (params.row?.id) {
          return `(${params.row?.userPortfolio?.subscribedPortfolio?.id}) ${params.row?.userPortfolio?.subscribedPortfolio?.nameEn}`;
        }
        return '';
      },
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink
              startIcon={<OpenInNewIcon />}
              href={locations.subscribedPortfolio(params.row?.userPortfolio?.subscribedPortfolio?.id)}
            >
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(UserPortfolioRequestStatus),
      minWidth: 200,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = userPortfolioRequestStatusToInfo[params.row?.status?.name as UserPortfolioRequestStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { totalUserPortfolioRequests, userPortfolioRequests, fetchStatus } = useSelector((state: ReducerState) =>
    userPortfolioRequestByRequestIdSelector(state, requestId),
  );
  const loading = fetchStatus === fetchStatuses.pending;

  useEffect(
    () => () => {
      dispatch(cleanUserPortfolioRequests());
    },
    [],
  );

  try {
    return (
      <>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">User Portfolio Requests &nbsp;</Typography>
        </Box>
        <Table
          ref={tableRef}
          wrapperHeight={300}
          fetchItems={fetchUserPortfolioRequests}
          rows={loading ? [] : userPortfolioRequests}
          columns={columns}
          loading={loading}
          rowCount={loading ? 0 : totalUserPortfolioRequests}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          density="compact"
          fetchItemsCustomParams={{ requestId }}
          requiredFilters={[{ field: 'userPortfolio.id', value: userPortfolioId, operator: 'is' }]}
          initialSortModel={[{ field: 'id', sort: 'desc' }]}
          initialAggregationModel={{ 'userPortfolio.fundAmount': 'sum' }}
          timesToSkipFilters={2}
        />
      </>
    );
  } catch (e: any) {
    return (
      <>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">User Portfolio Request</Typography>
        </Box>
        <Typography>Unexpected error happens: ${e.message}</Typography>
      </>
    );
  }
}
