import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useFormik } from 'formik';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import arLocale from 'i18n-iso-countries/langs/ar.json';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { ConfirmationDialog } from '../../../../components/AsyncDialog';
import { AsyncDialog, AsyncDialogRef } from '../../../../components/AsyncDialog/AsyncDialog';
import { ConfirmationDialogData } from '../../../../components/AsyncDialog/ConfirmationDialog';
import { CustomTextField } from '../../../../components/CustomTextField';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { EducationLevel, MaritalStatus } from '../../../../constants/nafaz';
import { kycFormFields } from '../../../../forms/fields/formFields';
import { editKycFormSchema } from '../../../../forms/validationSchema/formSchema';
import { usePermissions } from '../../../../hooks';
import { useAppDispatch } from '../../../../redux/hooks';
import { fetchKycByUserId, fetchUser, updateKyc } from '../../../../redux/modules/user/user.actions';
import {
  kycGenderOptionsLoadingSelector,
  kycStatusOptionsLoadingSelector,
  kycTypeOptionsLoadingSelector,
  userKycLoadingSelector,
  userSelector,
} from '../../../../redux/modules/user/user.selectors';
import {
  userCustodianDataLoadingSelector,
  userCustodianDataSelector,
} from '../../../../redux/modules/userCustodianData/userCustodianData.selectors';
import { KycType, UpdateKycRequest } from '../../../../types/kyc';
import * as permissions from '../../../../utils/permissions';
import { AddressesTable } from './AddressesTable';

countries.registerLocale(enLocale);
countries.registerLocale(arLocale);

const countryInfo = countries.getNames('en', { select: 'official' });
const countryArInfo = countries.getNames('ar', { select: 'official' });
const countryList = Object.entries(countryInfo).map(([value, label]) => ({ label, value }));
const listOfMaritalStatus = MaritalStatus.en;
const educationLevels = EducationLevel.en;

interface KycTabProps {
  id: number;
}

export function KycTab({ id }: KycTabProps) {
  const dispatch = useAppDispatch();
  const { editUserKyc, updateKycStatus, kycTypeOptions, kycStatusOptions, kycGenderOptions, error } =
    useSelector(userSelector);
  const { editUser } = useSelector(userSelector);
  const { enqueueSnackbar } = useSnackbar();
  const asyncDialogRef = useRef<AsyncDialogRef<ConfirmationDialogData, boolean>>(null);
  const canUpdate = usePermissions({ items: [permissions.Kyc.update] });
  const canGetAddresses = usePermissions({ items: [permissions.User.readAddress] });
  const isLoading = useSelector(userKycLoadingSelector);
  const isLoadingTypeOptions = useSelector(kycTypeOptionsLoadingSelector);
  const isLoadingStatusOptions = useSelector(kycStatusOptionsLoadingSelector);
  const isLoadingGenderOptions = useSelector(kycGenderOptionsLoadingSelector);
  const { data: custodianData } = useSelector(userCustodianDataSelector);
  const isLoadingCustodianData = useSelector(userCustodianDataLoadingSelector);
  const isSaveLoading = isLoading || updateKycStatus === fetchStatuses.pending;
  const { handleSubmit, values, handleChange, touched, errors, setFieldValue, resetForm } = useFormik({
    initialValues: {
      [kycFormFields.id]: 0,
      [kycFormFields.fullNameEn]: '',
      [kycFormFields.fullNameAr]: '',
      [kycFormFields.firstNameEn]: '',
      [kycFormFields.firstNameAr]: '',
      [kycFormFields.fatherNameAr]: '',
      [kycFormFields.fatherNameEn]: '',
      [kycFormFields.grandFatherNameAr]: '',
      [kycFormFields.grandFatherNameEn]: '',
      [kycFormFields.familyNameEn]: '',
      [kycFormFields.familyNameAr]: '',
      [kycFormFields.nationalId]: editUser?.nationalIdNumber || '',
      [kycFormFields.type]: 1,
      [kycFormFields.gender]: 1,
      [kycFormFields.nationalityEn]: '',
      [kycFormFields.nationalityAr]: '',
      [kycFormFields.country]: '',
      [kycFormFields.email]: editUser?.email || '',
      [kycFormFields.phone]: editUser?.mobile || '',
      [kycFormFields.address]: '',
      [kycFormFields.birthDate]: new Date(),
      [kycFormFields.idIssueDate]: new Date(),
      [kycFormFields.iqamaExpiryDate]: undefined,
      [kycFormFields.maritalStatus]: '',
      [kycFormFields.educationLevel]: '',
      [kycFormFields.companyName]: '',
      [kycFormFields.idExpiryDate]: new Date(),
      [kycFormFields.issueLocationAr]: '',
      [kycFormFields.issueLocationEn]: '',
      [kycFormFields.assuranceLevel]: '',
      [kycFormFields.language]: 'en',
      [kycFormFields.employerAddress]: '',
      [kycFormFields.fiveYearsFinancialSector]: false,
      [kycFormFields.fiveYearsFinancialSectorDescription]: '',
      [kycFormFields.boardDirectorsMember]: false,
      [kycFormFields.boardDirectorsMemberDescription]: '',
      [kycFormFields.individualWithHighProfilePoliticalRole]: false,
      [kycFormFields.individualWithHighProfilePoliticalRoleDescription]: '',
      [kycFormFields.notBeneficialOwner]: false,
      [kycFormFields.notBeneficialOwnerDescription]: '',
      [kycFormFields.status]: 1,
    },
    validationSchema: editKycFormSchema,
    onSubmit: async (formValues) => {
      const payload: UpdateKycRequest = {
        userId: id,
        ...formValues,
        nationalityEn: countryInfo[formValues.nationalityEn],
        nationalityAr: countryArInfo[formValues.nationalityAr],
      };

      if (!editUserKyc?.confirmDate) {
        const isConfirmed = await asyncDialogRef.current?.show({
          title: 'Update not confirmed Kyc data',
          description: (
            <p>
              KYC is not confirmed by the user!
              <br />
              Do you want to confirm it?
            </p>
          ),
          acceptButtonText: 'Confirm',
          rejectButtonText: 'Save Only',
        });

        if (isConfirmed) {
          payload.confirmDate = new Date();
        }
      }

      dispatch(updateKyc(payload));
    },
  });

  const onResetForm = () => {
    if (editUserKyc) {
      const nationalityCode = countries.getAlpha2Code(editUserKyc.nationalityEn, 'en');

      resetForm({
        values: {
          [kycFormFields.id]: editUserKyc.id,
          [kycFormFields.fullNameEn]: editUserKyc.fullNameEn,
          [kycFormFields.fullNameAr]: editUserKyc.fullNameAr,
          [kycFormFields.firstNameEn]: editUserKyc.firstNameEn,
          [kycFormFields.firstNameAr]: editUserKyc.firstNameAr,
          [kycFormFields.fatherNameAr]: editUserKyc.fatherNameAr || '',
          [kycFormFields.fatherNameEn]: editUserKyc.fatherNameEn || '',
          [kycFormFields.grandFatherNameAr]: editUserKyc.grandFatherNameAr || '',
          [kycFormFields.grandFatherNameEn]: editUserKyc.grandFatherNameEn || '',
          [kycFormFields.familyNameEn]: editUserKyc.familyNameEn,
          [kycFormFields.familyNameAr]: editUserKyc.familyNameAr,
          [kycFormFields.nationalId]: editUserKyc.nationalId,
          [kycFormFields.type]: editUserKyc.type?.id,
          [kycFormFields.gender]: editUserKyc.gender?.id,
          [kycFormFields.nationalityEn]: nationalityCode,
          [kycFormFields.nationalityAr]: nationalityCode,
          [kycFormFields.country]: editUserKyc.country || '',
          [kycFormFields.idExpiryDate]: editUserKyc.idExpiryDate ? new Date(editUserKyc.idExpiryDate) : new Date(),
          [kycFormFields.issueLocationAr]: editUserKyc.issueLocationAr || '',
          [kycFormFields.issueLocationEn]: editUserKyc.issueLocationEn || '',
          [kycFormFields.assuranceLevel]: editUserKyc.assuranceLevel || '',
          [kycFormFields.language]: editUserKyc.language || '',
          [kycFormFields.email]: editUserKyc.email,
          [kycFormFields.phone]: editUserKyc.phone,
          [kycFormFields.address]: editUserKyc.address,
          [kycFormFields.birthDate]: editUserKyc.birthDate ? new Date(editUserKyc.birthDate) : new Date(),
          [kycFormFields.idIssueDate]: editUserKyc.idIssueDate ? new Date(editUserKyc.idIssueDate) : new Date(),
          [kycFormFields.maritalStatus]: editUserKyc.maritalStatus || '',
          [kycFormFields.educationLevel]: editUserKyc.educationLevel || '',
          [kycFormFields.companyName]: editUserKyc.companyName || '',
          [kycFormFields.employerAddress]: editUserKyc.employerAddress || '',
          [kycFormFields.fiveYearsFinancialSector]: editUserKyc.fiveYearsFinancialSector || false,
          [kycFormFields.fiveYearsFinancialSectorDescription]: editUserKyc.fiveYearsFinancialSectorDescription || '',
          [kycFormFields.boardDirectorsMember]: editUserKyc.boardDirectorsMember || false,
          [kycFormFields.boardDirectorsMemberDescription]: editUserKyc.boardDirectorsMemberDescription || '',
          [kycFormFields.individualWithHighProfilePoliticalRole]:
            editUserKyc.individualWithHighProfilePoliticalRole || false,
          [kycFormFields.individualWithHighProfilePoliticalRoleDescription]:
            editUserKyc.individualWithHighProfilePoliticalRoleDescription || '',
          [kycFormFields.notBeneficialOwner]: editUserKyc.notBeneficialOwner || false,
          [kycFormFields.notBeneficialOwnerDescription]: editUserKyc.notBeneficialOwnerDescription || '',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          [kycFormFields.iqamaExpiryDate]: editUserKyc.iqamaExpiryDate
            ? new Date(editUserKyc.iqamaExpiryDate)
            : undefined,
          [kycFormFields.status]: editUserKyc.status?.id,
        },
      });
    } else {
      resetForm();
    }
  };

  useEffect(() => {
    if (updateKycStatus === fetchStatuses.success) {
      enqueueSnackbar(editUserKyc?.id ? 'Kyc updated!' : 'Kyc created!', { variant: 'success' });
      dispatch(fetchKycByUserId(id));
      dispatch(fetchUser(id));
    }
  }, [updateKycStatus]);

  useEffect(() => {
    onResetForm();
  }, [editUserKyc]);

  const isCitizen = useCallback((kycTypeValue: number) => {
    const kycType = kycTypeOptions.find(({ id: ktId }) => ktId === kycTypeValue);

    return kycType?.name === KycType.CITIZEN;
  }, []);

  useEffect(() => {
    if (isCitizen(values[kycFormFields.type])) {
      setFieldValue(kycFormFields.nationalityEn, 'SA');
      setFieldValue(kycFormFields.nationalityAr, 'SA');
    }
  }, [values[kycFormFields.type]]);

  useEffect(() => {
    setFieldValue(kycFormFields.nationalityAr, values[kycFormFields.nationalityEn]);
  }, [values[kycFormFields.nationalityEn]]);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={3}>
            <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
              <Grid
                sx={{ width: '100%', height: '100%', textAllign: 'center' }}
                container
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
              >
                {isLoadingCustodianData ? (
                  <Skeleton sx={{ padding: 1 }} variant="rectangular" width={226} height={58} />
                ) : (
                  <Typography>
                    <strong>Custodian Reference number:</strong> {custodianData?.referenceNumber || '-'}
                  </Typography>
                )}
                {isLoading ? (
                  <Skeleton sx={{ padding: 1 }} variant="circular" width={124} height={124} />
                ) : (
                  <Box
                    sx={{
                      border: '1px dashed rgba(145, 158, 171, 0.32)',
                      borderRadius: '50%',
                      padding: 1,
                    }}
                  >
                    <Avatar sx={{ width: 124, height: 124 }} src={`https://i.pravatar.cc/150?u=${id}`} />
                  </Box>
                )}
                {isLoading ? (
                  <Skeleton variant="rectangular" width={142} height={38} sx={{ mt: 2 }} />
                ) : (
                  <FormControl size="small" sx={{ mt: 2 }} margin="normal" error={touched.status && !!errors.status}>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      name="status"
                      value={values[kycFormFields.status] || ''}
                      label="Status"
                      onChange={handleChange}
                      disabled={isLoadingStatusOptions || !canUpdate}
                    >
                      {kycStatusOptions.map(({ id: ksId, name }) => (
                        <MenuItem key={ksId} value={ksId}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
              <Grid container flexDirection="column" paddingX={2} paddingBottom={3}>
                <Typography variant="h6">Main information</Typography>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="fullNameEn"
                          fullWidth
                          name={kycFormFields.fullNameEn}
                          label="Full Name En"
                          value={values[kycFormFields.fullNameEn]}
                          onChange={handleChange}
                          error={touched.fullNameEn && !!errors.fullNameEn}
                          helperText={touched.fullNameEn && errors.fullNameEn}
                          autoComplete="full-name-em"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="fullNameAr"
                          fullWidth
                          name={kycFormFields.fullNameAr}
                          label="Full Name Ar"
                          value={values[kycFormFields.fullNameAr]}
                          onChange={handleChange}
                          error={touched.fullNameAr && !!errors.fullNameAr}
                          helperText={touched.fullNameAr && errors.fullNameAr}
                          autoComplete="full-name-ar"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="firstNameEn"
                          fullWidth
                          name={kycFormFields.firstNameEn}
                          label="First Name En"
                          value={values[kycFormFields.firstNameEn]}
                          onChange={handleChange}
                          error={touched.firstNameEn && !!errors.firstNameEn}
                          helperText={touched.firstNameEn && errors.firstNameEn}
                          autoComplete="first-name-em"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="firstNameAr"
                          fullWidth
                          name={kycFormFields.firstNameAr}
                          label="First Name Ar"
                          value={values[kycFormFields.firstNameAr]}
                          onChange={handleChange}
                          error={touched.firstNameAr && !!errors.firstNameAr}
                          helperText={touched.firstNameAr && errors.firstNameAr}
                          autoComplete="first-name-ar"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="familyNameEn"
                          fullWidth
                          name={kycFormFields.familyNameEn}
                          label="Family Name En"
                          value={values[kycFormFields.familyNameEn]}
                          onChange={handleChange}
                          error={touched.familyNameEn && !!errors.familyNameEn}
                          helperText={touched.familyNameEn && errors.familyNameEn}
                          autoComplete="family-name-en"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="familyNameAr"
                          fullWidth
                          name={kycFormFields.familyNameAr}
                          label="Family Name Ar"
                          value={values[kycFormFields.familyNameAr]}
                          onChange={handleChange}
                          error={touched.familyNameAr && !!errors.familyNameAr}
                          helperText={touched.familyNameAr && errors.familyNameAr}
                          autoComplete="family-name-ar"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="fatherNameEn"
                          fullWidth
                          name={kycFormFields.fatherNameEn}
                          label="Father Name En"
                          value={values[kycFormFields.fatherNameEn]}
                          onChange={handleChange}
                          error={touched.fatherNameEn && !!errors.fatherNameEn}
                          helperText={touched.fatherNameEn && errors.fatherNameEn}
                          autoComplete="father-name-en"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="fatherNameAr"
                          fullWidth
                          name={kycFormFields.fatherNameAr}
                          label="Father Name Ar"
                          value={values[kycFormFields.fatherNameAr]}
                          onChange={handleChange}
                          error={touched.fatherNameAr && !!errors.fatherNameAr}
                          helperText={touched.fatherNameAr && errors.fatherNameAr}
                          autoComplete="father-name-ar"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="grandFatherNameEn"
                          fullWidth
                          name={kycFormFields.grandFatherNameEn}
                          label="Grand Father Name En"
                          value={values[kycFormFields.grandFatherNameEn]}
                          onChange={handleChange}
                          error={touched.grandFatherNameEn && !!errors.grandFatherNameEn}
                          helperText={touched.grandFatherNameEn && errors.grandFatherNameEn}
                          autoComplete="grand-father-name-en"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="grandFatherNameAr"
                          fullWidth
                          name={kycFormFields.grandFatherNameAr}
                          label="Grand Father Name Ar"
                          value={values[kycFormFields.grandFatherNameAr]}
                          onChange={handleChange}
                          error={touched.grandFatherNameAr && !!errors.grandFatherNameAr}
                          helperText={touched.grandFatherNameAr && errors.grandFatherNameAr}
                          autoComplete="grand-father-name-ar"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="nationalId"
                          fullWidth
                          name={kycFormFields.nationalId}
                          type="number"
                          label="National Id"
                          value={values[kycFormFields.nationalId]}
                          onChange={handleChange}
                          error={touched.nationalId && !!errors.nationalId}
                          helperText={touched.nationalId && errors.nationalId}
                          margin="normal"
                          required
                          disabled={!canUpdate}
                          autoFocus
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="email"
                          fullWidth
                          name={kycFormFields.email}
                          label="Email"
                          value={values[kycFormFields.email]}
                          onChange={handleChange}
                          error={touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          autoComplete="email"
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={values[kycFormFields.phone] as string & { __tag: 'E164Number' }}
                          name={kycFormFields.phone}
                          id="phone"
                          label="Phone"
                          type="phone"
                          inputComponent={CustomTextField}
                          numberInputProps={{
                            margin: 'normal',
                            required: true,
                            fullWidth: true,
                            id: 'phone',
                            name: kycFormFields.phone,
                            label: 'Phone',
                            type: 'phone',
                            error: touched.phone && !!errors.phone,
                            helperText: touched.phone && errors.phone,
                            autoComplete: 'phone',
                          }}
                          disabled={!canUpdate}
                          onChange={(value: string & { __tag: 'E164Number' }) =>
                            handleChange({
                              target: {
                                name: kycFormFields.phone,
                                type: 'phone',
                                value,
                              },
                            })
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl fullWidth margin="none" error={touched.birthDate && !!errors.birthDate}>
                          <DatePicker
                            label="Birth Date"
                            value={values[kycFormFields.birthDate] || new Date()}
                            onChange={(date) => {
                              setFieldValue(kycFormFields.birthDate, date);
                            }}
                            slotProps={{
                              textField: {
                                name: kycFormFields.birthDate,
                                id: 'birthDate',
                                label: 'Birth Date',
                                fullWidth: true,
                                margin: 'normal',
                              },
                            }}
                            disabled={!canUpdate}
                          />
                          {touched.birthDate && <FormHelperText>{errors.birthDate as string}</FormHelperText>}
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="address"
                          fullWidth
                          name={kycFormFields.address}
                          label="Address"
                          value={values[kycFormFields.address]}
                          onChange={handleChange}
                          error={touched.address && !!errors.address}
                          autoComplete="address"
                          helperText={touched.address && errors.address}
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id={kycFormFields.issueLocationEn}
                          fullWidth
                          name={kycFormFields.issueLocationEn}
                          label="Issue Location En"
                          value={values[kycFormFields.issueLocationEn]}
                          onChange={handleChange}
                          error={touched.issueLocationEn && !!errors.issueLocationEn}
                          autoComplete="issueLocationEn"
                          helperText={touched.issueLocationEn && errors.issueLocationEn}
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id={kycFormFields.issueLocationAr}
                          fullWidth
                          name={kycFormFields.issueLocationAr}
                          label="Issue Location Ar"
                          value={values[kycFormFields.issueLocationAr]}
                          onChange={handleChange}
                          error={touched.issueLocationAr && !!errors.issueLocationAr}
                          autoComplete="issueLocationAr"
                          helperText={touched.issueLocationAr && errors.issueLocationAr}
                          margin="normal"
                          required
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl fullWidth margin="none" error={touched.idIssueDate && !!errors.idIssueDate}>
                          <DatePicker
                            label="Id Issue Date"
                            value={values[kycFormFields.idIssueDate]}
                            onChange={(date) => {
                              setFieldValue(kycFormFields.idIssueDate, date);
                            }}
                            slotProps={{
                              textField: {
                                name: kycFormFields.idIssueDate,
                                id: 'idIssueDate',
                                label: 'Id Issue Date',
                                fullWidth: true,
                                margin: 'normal',
                              },
                            }}
                            disabled={!canUpdate}
                          />
                          {touched.idIssueDate && <FormHelperText>{errors.idIssueDate as string}</FormHelperText>}
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl fullWidth margin="none" error={touched.idExpiryDate && !!errors.idExpiryDate}>
                          <DatePicker
                            label="Id Expiry Date"
                            value={values[kycFormFields.idExpiryDate]}
                            onChange={(date) => {
                              setFieldValue(kycFormFields.idExpiryDate, date);
                            }}
                            slotProps={{
                              textField: {
                                name: kycFormFields.idExpiryDate,
                                id: 'idExpiryDate',
                                label: 'Id Expiry Date',
                                fullWidth: true,
                                margin: 'normal',
                              },
                            }}
                            disabled={!canUpdate}
                          />
                          {touched.idExpiryDate && <FormHelperText>{errors.idExpiryDate as string}</FormHelperText>}
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl
                          fullWidth
                          margin="none"
                          error={touched.iqamaExpiryDate && !!errors.iqamaExpiryDate}
                        >
                          <DatePicker
                            label="Iqama Expiry Date"
                            value={values[kycFormFields.iqamaExpiryDate] || null}
                            onChange={(date) => {
                              setFieldValue(kycFormFields.iqamaExpiryDate, date);
                            }}
                            slotProps={{
                              textField: {
                                name: kycFormFields.iqamaExpiryDate,
                                id: 'iqamaExpiryDate',
                                label: 'Iqama Expiry Date',
                                fullWidth: true,
                                margin: 'normal',
                              },
                            }}
                            disabled={!canUpdate}
                          />
                          {touched.iqamaExpiryDate && <FormHelperText>{errors?.iqamaExpiryDate}</FormHelperText>}
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl fullWidth margin="normal" error={touched.type && !!errors.type}>
                          <InputLabel id="type-label">Type</InputLabel>
                          <Select
                            labelId="type-label"
                            id="type"
                            name={kycFormFields.type}
                            value={values[kycFormFields.type] || ''}
                            label="Type"
                            onChange={handleChange}
                            disabled={isLoadingTypeOptions || !canUpdate}
                          >
                            {kycTypeOptions.map(({ id: ktId, name }) => (
                              <MenuItem key={ktId} value={ktId}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.type && <FormHelperText>{errors.type}</FormHelperText>}
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl fullWidth margin="normal" error={touched.gender && !!errors.gender}>
                          <InputLabel id="gender-label">Gender</InputLabel>
                          <Select
                            labelId="gender-label"
                            id="gender"
                            name={kycFormFields.gender}
                            value={values[kycFormFields.gender] || ''}
                            label="Gender"
                            onChange={handleChange}
                            disabled={isLoadingGenderOptions || !canUpdate}
                          >
                            {kycGenderOptions.map(({ id: kgId, name }) => (
                              <MenuItem key={kgId} value={kgId}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.gender && <FormHelperText>{errors.gender}</FormHelperText>}
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl fullWidth margin="normal" error={touched.nationalityEn && !!errors.nationalityEn}>
                          <InputLabel id="nationalityEn-label">Nationality</InputLabel>
                          <Select
                            labelId="nationalityEn-label"
                            id="nationalityEn"
                            name="nationalityEn"
                            value={values[kycFormFields.nationalityEn]}
                            label="Nationality"
                            onChange={handleChange}
                            disabled={isCitizen(values[kycFormFields.type]) || !canUpdate}
                            MenuProps={{ sx: { paper: { maxHeight: 100 } } }}
                          >
                            {countryList.map(({ label, value }) => (
                              <MenuItem key={label} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl fullWidth margin="normal" error={touched.country && !!errors.country}>
                          <InputLabel id="country-label">Country</InputLabel>
                          <Select
                            labelId="country-label"
                            id="country"
                            name="country"
                            value={values[kycFormFields.country]}
                            label="Country"
                            onChange={handleChange}
                            disabled={!canUpdate}
                            MenuProps={{ sx: { paper: { maxHeight: 100 } } }}
                          >
                            {countryList.map(({ label, value }) => (
                              <MenuItem key={label} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="assuranceLevel"
                          fullWidth
                          name={kycFormFields.assuranceLevel}
                          label="Assurance Level"
                          value={values[kycFormFields.assuranceLevel]}
                          onChange={handleChange}
                          error={touched.assuranceLevel && !!errors.assuranceLevel}
                          helperText={touched.assuranceLevel && errors.assuranceLevel}
                          autoComplete="assuranceLevel"
                          margin="normal"
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl fullWidth margin="normal" error={touched.language && !!errors.language}>
                          <InputLabel id="language-label">Language</InputLabel>
                          <Select
                            labelId="language-label"
                            id="language"
                            name="language"
                            value={values[kycFormFields.language]}
                            label="Language"
                            onChange={handleChange}
                            disabled={!canUpdate}
                            MenuProps={{ sx: { paper: { maxHeight: 100 } } }}
                          >
                            {[
                              { label: 'English', value: 'en' },
                              { label: 'Arabian', value: 'ar' },
                            ].map(({ label, value }) => (
                              <MenuItem key={label} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            &nbsp;
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
              <Grid container flexDirection="column" paddingX={2} paddingBottom={3}>
                <Typography variant="h6">Additional information</Typography>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl fullWidth margin="normal" error={touched.maritalStatus && !!errors.maritalStatus}>
                          <InputLabel id="maritalStatus-label">Marital Status</InputLabel>
                          <Select
                            labelId="maritalStatus-label"
                            id={kycFormFields.maritalStatus}
                            name={kycFormFields.maritalStatus}
                            value={values[kycFormFields.maritalStatus]}
                            label="Marital Status"
                            onChange={handleChange}
                            disabled={!canUpdate}
                            MenuProps={{ sx: { paper: { maxHeight: 100 } } }}
                          >
                            {listOfMaritalStatus.map(({ label, value }) => (
                              <MenuItem key={label} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControl
                          fullWidth
                          margin="normal"
                          error={touched.educationLevel && !!errors.educationLevel}
                        >
                          <InputLabel id="educationLevel-label">Education Level</InputLabel>
                          <Select
                            labelId="educationLevel-label"
                            id={kycFormFields.educationLevel}
                            name={kycFormFields.educationLevel}
                            value={values[kycFormFields.educationLevel]}
                            label="Education Level"
                            onChange={handleChange}
                            disabled={!canUpdate}
                            MenuProps={{ sx: { paper: { maxHeight: 100 } } }}
                          >
                            {educationLevels.map(({ label, value }) => (
                              <MenuItem key={label} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="companyName"
                          fullWidth
                          name={kycFormFields.companyName}
                          label="Company Name"
                          value={values[kycFormFields.companyName]}
                          onChange={handleChange}
                          error={touched.companyName && !!errors.companyName}
                          helperText={touched.companyName && errors.companyName}
                          autoComplete="companyName"
                          margin="normal"
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id="employerAddress"
                          fullWidth
                          name={kycFormFields.employerAddress}
                          label="Employer Address"
                          value={values[kycFormFields.employerAddress]}
                          onChange={handleChange}
                          error={touched.employerAddress && !!errors.employerAddress}
                          helperText={touched.employerAddress && errors.employerAddress}
                          autoComplete="employerAddress"
                          margin="normal"
                          disabled={!canUpdate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControlLabel
                          sx={{ mt: 2 }}
                          label="Five Years Financial Sector"
                          labelPlacement="start"
                          componentsProps={{
                            typography: { textTransform: 'capitalize' },
                          }}
                          name={kycFormFields.fiveYearsFinancialSector}
                          id={kycFormFields.fiveYearsFinancialSector}
                          disabled={!canUpdate}
                          control={
                            <Switch
                              name={kycFormFields.fiveYearsFinancialSector}
                              checked={values[kycFormFields.fiveYearsFinancialSector]}
                              onChange={handleChange}
                            />
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id={kycFormFields.fiveYearsFinancialSectorDescription}
                          fullWidth
                          multiline
                          name={kycFormFields.fiveYearsFinancialSectorDescription}
                          label="Five Years Financial Sector Description"
                          disabled={!values[kycFormFields.fiveYearsFinancialSector] || !canUpdate}
                          value={values[kycFormFields.fiveYearsFinancialSectorDescription]}
                          onChange={handleChange}
                          error={
                            touched.fiveYearsFinancialSectorDescription && !!errors.fiveYearsFinancialSectorDescription
                          }
                          helperText={
                            touched.fiveYearsFinancialSectorDescription && errors.fiveYearsFinancialSectorDescription
                          }
                          autoComplete="fiveYearsFinancialSectorDescription"
                          margin="normal"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControlLabel
                          sx={{ mt: 2 }}
                          label="Board Directors Member"
                          labelPlacement="start"
                          componentsProps={{
                            typography: { textTransform: 'capitalize' },
                          }}
                          name={kycFormFields.boardDirectorsMember}
                          id={kycFormFields.boardDirectorsMember}
                          disabled={!canUpdate}
                          control={
                            <Switch
                              name={kycFormFields.boardDirectorsMember}
                              checked={values[kycFormFields.boardDirectorsMember]}
                              onChange={handleChange}
                            />
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id={kycFormFields.boardDirectorsMemberDescription}
                          fullWidth
                          multiline
                          name={kycFormFields.boardDirectorsMemberDescription}
                          label="Board Directors Member Description"
                          disabled={!values[kycFormFields.boardDirectorsMember] || !canUpdate}
                          value={values[kycFormFields.boardDirectorsMemberDescription]}
                          onChange={handleChange}
                          error={touched.boardDirectorsMemberDescription && !!errors.boardDirectorsMemberDescription}
                          helperText={touched.boardDirectorsMemberDescription && errors.boardDirectorsMemberDescription}
                          autoComplete="boardDirectorsMemberDescription"
                          margin="normal"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControlLabel
                          sx={{ mt: 2 }}
                          label="Individual With High Profile Political Role"
                          labelPlacement="start"
                          componentsProps={{
                            typography: { textTransform: 'capitalize' },
                          }}
                          name={kycFormFields.individualWithHighProfilePoliticalRole}
                          id={kycFormFields.individualWithHighProfilePoliticalRole}
                          disabled={!canUpdate}
                          control={
                            <Switch
                              name={kycFormFields.individualWithHighProfilePoliticalRole}
                              checked={values[kycFormFields.individualWithHighProfilePoliticalRole]}
                              onChange={handleChange}
                            />
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id={kycFormFields.individualWithHighProfilePoliticalRoleDescription}
                          fullWidth
                          multiline
                          name={kycFormFields.individualWithHighProfilePoliticalRoleDescription}
                          label="Individual With High Profile Political Role Description"
                          disabled={!values[kycFormFields.individualWithHighProfilePoliticalRole] || !canUpdate}
                          value={values[kycFormFields.individualWithHighProfilePoliticalRoleDescription]}
                          onChange={handleChange}
                          error={
                            touched.individualWithHighProfilePoliticalRoleDescription &&
                            !!errors.individualWithHighProfilePoliticalRoleDescription
                          }
                          helperText={
                            touched.individualWithHighProfilePoliticalRoleDescription &&
                            errors.individualWithHighProfilePoliticalRoleDescription
                          }
                          autoComplete="individualWithHighProfilePoliticalRoleDescription"
                          margin="normal"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <FormControlLabel
                          sx={{ mt: 2 }}
                          label="Not Beneficial Owner"
                          labelPlacement="start"
                          componentsProps={{
                            typography: { textTransform: 'capitalize' },
                          }}
                          name={kycFormFields.notBeneficialOwner}
                          id={kycFormFields.notBeneficialOwner}
                          disabled={!canUpdate}
                          control={
                            <Switch
                              name={kycFormFields.notBeneficialOwner}
                              checked={values[kycFormFields.notBeneficialOwner]}
                              onChange={handleChange}
                            />
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} />
                      ) : (
                        <TextField
                          id={kycFormFields.notBeneficialOwnerDescription}
                          fullWidth
                          multiline
                          name={kycFormFields.notBeneficialOwnerDescription}
                          label="Not Beneficial Owner Description"
                          disabled={!values[kycFormFields.notBeneficialOwner] || !canUpdate}
                          value={values[kycFormFields.notBeneficialOwnerDescription]}
                          onChange={handleChange}
                          error={touched.notBeneficialOwnerDescription && !!errors.notBeneficialOwnerDescription}
                          helperText={touched.notBeneficialOwnerDescription && errors.notBeneficialOwnerDescription}
                          autoComplete="notBeneficialOwnerDescription"
                          margin="normal"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {canGetAddresses && (
            <Grid item xs={12}>
              <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
                <AddressesTable userId={id} />
              </Paper>
            </Grid>
          )}
        </Grid>
        <Grid>
          <Box m={1} p={1} display="flex" justifyContent="right" alignItems="flex-end">
            <div />
            <Box sx={{ mt: 3, mb: 2 }} hidden={!canUpdate}>
              <LoadingButton
                id="SaveButton"
                type="submit"
                color="primary"
                variant="contained"
                sx={{ mr: 2 }}
                endIcon={<SaveIcon />}
                loading={isSaveLoading}
                loadingPosition="end"
              >
                Save
              </LoadingButton>
              <Button
                id="DiscardButton"
                variant="contained"
                color="warning"
                startIcon={<RestartAltIcon />}
                onClick={onResetForm}
              >
                Discard
              </Button>
            </Box>

            <AsyncDialog ref={asyncDialogRef} dialog={ConfirmationDialog} />
          </Box>
        </Grid>
      </form>
    </>
  );
}
