import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { GridActionsCellItemProps, GridRowParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions, useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import {
  changeDisplayCustom,
  cleanIndexFunds,
  fetchIndexFunds,
  updateIndexFund,
} from '../../redux/modules/indexFund/indexFund.actions';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';
import { locations } from '../../routes/locations';
import { parseJSON } from '../../utils/json';
import * as permissions from '../../utils/permissions';
import { ProtectedContent } from '../../components/ProtectedContent';
import { GridActionsCellItemLink } from '../../components/FakeLink';
import { IndexFund } from '../../types/indexFund';

export function IndexFundList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const { enqueueSnackbar } = useSnackbar();

  const canUpdate = usePermissions({ items: [permissions.IndexFund.update] });
  const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '100');

  const { totalIndexFunds, indexFunds, fetchStatus, deleteStatus } = useSelector(indexFundSelector);

  const onCustomDisplayChanged = async (indexFund: IndexFund) => {
    dispatch(changeDisplayCustom(indexFund));

    await dispatch(
      updateIndexFund({
        id: indexFund.id,
        customDisplay: !indexFund.customDisplay,
      }),
    ).unwrap();
  };

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'bloombergTicker',
      headerName: 'Bloomberg Ticker',
      minWidth: 50,
    },
    {
      field: 'code',
      headerName: 'Code',
      minWidth: 50,
    },
    {
      field: 'nameEn',
      headerName: 'Name En',
      minWidth: 50,
    },
    {
      field: 'nameAr',
      headerName: 'Name Ar',
      minWidth: 50,
    },
    {
      field: 'secondNameEn',
      headerName: 'Secondary Name En',
      minWidth: 50,
    },
    {
      field: 'secondNameAr',
      headerName: 'Secondary Name Ar',
      minWidth: 50,
    },
    {
      field: 'asset.nameEn',
      headerName: 'Asset',
      minWidth: 50,
      valueGetter: (params) => params.row?.asset?.nameEn,
    },
    {
      field: 'customDisplay',
      headerName: 'Custom Product',
      type: 'boolean',
      minWidth: 50,
      renderCell: (params: GridRenderCellParams) => (
        <Switch
          name="customDisplay"
          checked={params.row?.customDisplay}
          onChange={() => onCustomDisplayChanged(params.row)}
        />
      ),
    },
    {
      field: 'strategy.nameEn',
      headerName: 'Strategy',
      minWidth: 100,
      valueGetter: (params) => params.row?.strategy?.nameEn,
    },
    {
      field: 'manager.nameEn',
      headerName: 'Manager',
      minWidth: 50,
      valueGetter: (params) => params.row?.manager?.nameEn,
    },
    {
      field: 'objectiveEn',
      headerName: 'Objective En',
      minWidth: 50,
    },
    {
      field: 'objectiveAr',
      headerName: 'Objective Ar',
      minWidth: 50,
    },
    {
      field: 'geographicalFocus.nameEn',
      headerName: 'Geo. Focus',
      minWidth: 100,
      valueGetter: (params) => params.row?.geographicalFocus?.nameEn,
    },
    {
      field: 'shariaaComplaint',
      type: 'boolean',
      headerName: 'Shariaa',
      minWidth: 50,
    },
    {
      field: 'expenseRatio',
      headerName: 'Expense Ratio',
      type: 'number',
      minWidth: 20,
    },
    {
      field: 'valuationDay.nameEn',
      headerName: 'Valuation Day',
      minWidth: 50,
      valueGetter: (params) => params.row?.valuationDay?.nameEn,
    },
    {
      field: 'valuationFrequency.nameEn',
      headerName: 'Valuation Frequency',
      minWidth: 50,
      valueGetter: (params) => params.row?.valuationFrequency?.nameEn,
    },
    {
      field: 'type.name',
      headerName: 'Type',
      minWidth: 100,
      valueGetter: (params) => params.row?.type?.name,
    },
    {
      field: 'category.name',
      headerName: 'Category',
      minWidth: 50,
      valueGetter: (params) => params.row?.category?.name,
    },
    {
      field: 'currency.name',
      headerName: 'Currency',
      minWidth: 50,
      valueGetter: (params) => params.row?.currency?.name,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      minWidth: 50,
      valueGetter: (params) => params.row?.status?.name,
    },
    {
      field: 'netAssetValuePerUnit',
      headerName: 'Net Asset Value Per Unit',
      type: 'number',
      minWidth: 100,
    },
    {
      field: 'assetUnderManagement',
      headerName: 'Asset Under Management',
      type: 'number',
      minWidth: 100,
    },
    {
      field: 'managementFee',
      headerName: 'Management Fee',
      type: 'number',
      minWidth: 50,
    },
    {
      field: 'minimumSubscription',
      headerName: 'Minimum Subscription',
      type: 'number',
      minWidth: 50,
    },
    {
      field: 'minimumRedemption',
      headerName: 'Minimum Redemption',
      type: 'number',
      minWidth: 50,
    },
    {
      field: 'subscriptionFee',
      headerName: 'Subscription Fee',
      type: 'number',
      minWidth: 50,
    },
    {
      field: 'redemptionFee',
      headerName: 'Redemption Fee',
      type: 'number',
      minWidth: 50,
    },
    {
      field: 'benchmark.nameEn',
      headerName: 'Benchmark',
      minWidth: 50,
      valueGetter: (params) => params.row?.benchmark?.nameEn,
    },
    {
      field: 'topHoldings',
      headerName: 'Top Holdings',
      minWidth: 50,
    },
    {
      field: 'numberHoldings',
      headerName: 'Number Holdings',
      type: 'number',
      minWidth: 50,
    },
    {
      field: 'riskLevel.name',
      headerName: 'Risk Level',
      minWidth: 50,
      valueGetter: (params) => params.row?.riskLevel?.name,
    },
    {
      field: 'inceptionDate',
      headerName: 'Inception Date',
      type: 'date',
      width: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);

  const onAddNew = (): void => {
    navigate(locations.indexFund(0));
  };

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canUpdate) {
          actions.push(
            <GridActionsCellItemLink
              key={`indexFund-list-update-${params.row.id}`}
              icon={<OpenInNewIcon />}
              label="Actions"
              href={locations.indexFund(params.row.id)}
            />,
          );
        }

        return actions;
      },
    };

    if (canUpdate) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canUpdate]);

  useEffect(
    () => () => {
      dispatch(cleanIndexFunds());
    },
    [],
  );

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      enqueueSnackbar('Index Fund deleted!', { variant: 'success' });
    }
    if (deleteStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Index Fund deletion error!', { variant: 'error' });
    }
  }, [deleteStatus]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Index Funds &nbsp;
          <ProtectedContent items={[permissions.IndexFund.create]}>
            <Fab color="primary" size="small" aria-label="Create Index Fund" onClick={onAddNew}>
              <AddIcon />
            </Fab>
          </ProtectedContent>
        </Typography>
      </Box>

      <Table
        ref={tableRef}
        fetchItems={fetchIndexFunds}
        rows={indexFunds}
        columns={columns}
        loading={fetchStatus === fetchStatuses.pending}
        rowCount={totalIndexFunds}
        initialFilterModel={parseJSON(initialFilterModel)}
        initialPage={+initialPage}
        initialPageSize={+initialPageSize}
        onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
        onPageChange={(page) => setInitialPage(`${page}`)}
        onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
        initialColumnVisibilityModel={{
          nameAr: false,
          secondNameEn: false,
          secondNameAr: false,
          objectiveEn: false,
          objectiveAr: false,
          shariaaComplaint: false,
          'manager.nameEn': false,
          'valuationDay.nameEn': false,
          'valuationFrequency.nameEn': false,
          'currency.name': false,
          netAssetValuePerUnit: false,
          assetUnderManagement: false,
          inceptionDate: false,
          managementFee: false,
          minimumSubscription: false,
          minimumRedemption: false,
          subscriptionFee: false,
          redemptionFee: false,
          expenseRatio: false,
          topHoldings: false,
          numberHoldings: false,
        }}
      />
    </Container>
  );
}
